/* Start Font Import */
@font-face {
  font-family: "Somar";
  src: url("./fonts/SomarSans-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Somar";
  src: url("./fonts/SomarSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Somar";
  src: url("./fonts/SomarSans-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Somar";
  src: url("./fonts/SomarSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
/* End Font Import */

/* Start Main Rules */

:root {
  --main-color: #f8b34f;
  --dark-color: #1f2034;
}

html {
  height: -webkit-fill-available;
}

body[lang="ar"] {
  font-family: "Somar", sans-serif;
  direction: rtl;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  background-color: #fff;
  height: 100dvh;
  width: 100%;
  input,
  select,
  textarea {
    font-family: "Somar", sans-serif !important;
  }
  .Toastify * {
    z-index: 5656565656;
    font-family: "Somar", sans-serif !important;
  }
}

.PhotoView-Portal {
  direction: ltr;
}
/* End Main Rules */
