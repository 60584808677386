.login-page-admin {
  width: 100%;
  min-height: 100dvb;
  height: 100%;
  overflow: hidden;
  background-color: #3e3e3e !important;

  display: flex;
  justify-content: center;
  align-items: center;
  .background {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    z-index: 1;
    bottom: 0;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    position: relative;
    z-index: 4;
    height: 70%;
    .login-box {
      height: 100%;
      width: 400px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;
      &.loading {
        .head-box {
          height: 80%;
          flex-direction: column;
          background-color: rgba(99, 255, 219, 0.444);
          transition: 0.5s;
          .image {
            width: 280px;
            height: 280px;
            margin-top: 20px;
            transition: 0.5s;
          }
        }
        .box {
          min-height: 10%;
          padding-top: 10px;
          transition: 0.5s;

          .group {
            display: none;
          }
          .head-title {
            display: none;
          }
        }
      }
      .head-box {
        height: 150px;
        background: rgba(179, 179, 179, 0.25);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(6.3px);
        -webkit-backdrop-filter: blur(6.3px);
        border: 1px solid rgba(179, 179, 179, 0.42);
        display: flex;
        width: calc(100% - 40px);
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        transition: 0.5s;

        .image {
          width: 120px;
          height: 120px;
          transition: 0.5s;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .group {
          width: calc(100% - 150px);
          height: 85%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .title {
            font-weight: bold;
            position: relative;
            font-size: 18px;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;

            &::after {
              content: "";
              bottom: -10px;
              left: 0;
              height: 1px;
              background-color: #fff;
              width: 100%;
              position: absolute;
            }
          }
        }
        .info {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;
          .grouping {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 2px;
            width: 48%;
            height: 20px;
            &.spic {
              justify-content: end;
              .value {
                direction: ltr;
              }
            }
            .icon {
              width: 20px;
              height: 20px;
            }
            .title {
              font-size: 14px;
              width: calc(100% - 20px);
            }
          }
        }
      }
      .box {
        min-height: calc(96% - 180px);
        height: max-content;
        background: rgba(179, 179, 179, 0.25);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(6.3px);
        -webkit-backdrop-filter: blur(6.3px);
        border: 1px solid rgba(179, 179, 179, 0.42);
        width: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        gap: 40px;
        align-items: center;
        padding-bottom: 20px;
        transition: 0.5s;

        .head-title {
          font-size: 24px;
          font-weight: bold;
          margin: 20px 0 10px;
        }
        .group {
          height: 40%;
          width: 90%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          flex-direction: column;
          input {
            width: 100%;
            outline: none;
            border: 1px solid #3e3e3e;
            background-color: #fff;
            height: 50px;
            border-radius: 10px;
            text-align: center;
          }
        }
        .login-btn {
          width: 90%;
          height: 40px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          padding: 10px 0;
          align-items: center;
          background-color: #ffc73d;
          color: #000;
          font-weight: bold;
          font-size: 18px;
          cursor: pointer;
          margin-bottom: 6px;
        }
      }
    }
    .left {
      height: 100%;
      width: 280px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      .group {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        .company-name {
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          text-transform: uppercase;
          color: #fff;
          font-size: 24px;
          direction: ltr;
        }
        .logo {
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 90%;
            height: 90%;
            object-fit: contain;
          }
        }
      }
      .copyright {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 10px;
        padding-left: 10px;
        font-weight: bold;
        text-transform: uppercase;
        flex-direction: column;
        width: 379px;
        position: relative;
        left: 50px;
        direction: ltr;
        .title {
          font-size: 24px;
          margin-bottom: -10px;
        }
        .info {
          font-size: 14px;
          font-weight: 600;
        }
      }
      .box {
        background: rgba(179, 179, 179, 0.25);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(6.3px);
        -webkit-backdrop-filter: blur(6.3px);
        border: 1px solid rgba(179, 179, 179, 0.42);
      }
    }
  }
}

@media (max-width: 768px) {
  .login-page-admin {
    align-items: start;
    gap: 40px;
    .container {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      width: 90%;
      justify-content: space-between;
      gap: 40px;
      height: 100%;
      .head-box {
        .value {
          font-size: 10px;
          font-weight: bold;
        }
      }
      .login-box {
        width: 100%;
        gap: 10px;
        height: 67%;
        .box {
          height: calc(100% - 90px);
        }
      }
      .left {
        width: 100%;
        height: 27%;
        .group {
          flex-direction: row;
          width: 100%;
          align-items: flex-end;
          justify-content: flex-end;
          .copyright {
            width: calc(100% - 120px);
            left: 0;
            height: 60px;
            .title {
              font-size: 18px;
            }
            .info {
              font-size: 12px;
            }
          }
          .logo {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
  }
}
