.home-page {
  padding: 0 10px;
  width: calc(100% - 20px);
  min-height: 100dvh;
  height: max-content;
  background-color: #eeeeee;
  padding-bottom: 10px;
  overflow: hidden;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    height: auto;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
      height: auto;
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0 7px;
  }
  .description {
    font-size: 15px;
    text-align: center;
  }

  .links {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 30px;
    .link {
      width: 100%;
      height: 73px;
      border-radius: 10px;
      border: 1px solid #ddd;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.177) 1.95px 1.95px 2.6px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 20px;
      font-weight: 900;
      text-transform: uppercase;
      color: #000;
      position: relative;
      overflow: hidden;
      img {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      i {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 28px;
        z-index: 1;
      }
      .link-title {
        z-index: 1;
      }
    }
  }
}
