.error-page {
  padding: 0 10px;
  width: calc(100% - 20px);
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  background-color: #111111;
  padding-bottom: 10px;
  overflow: hidden;
  padding-bottom: 120px;
  color: #a8a8a8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bolder;
  .span1 {
    color: #9f9f9f;
  }
  direction: ltr !important;
}
