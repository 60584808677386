.admin-websites-page {
  padding: 0 10px;
  width: calc(100% - 20px);
  min-height: 100dvh;
  height: max-content;
  background-color: #efefef;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-size: 20px;
    position: relative;
    width: 100%;
    font-weight: bold;
    .logout {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      border: 1px solid #121212;
      padding: 10px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      color: #000;
      text-decoration: none;
    }
  }

  .websites {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    gap: 20px;
    margin-top: 30px;
    .website {
      width: 100%;
      border: 1px solid #000;
      border-radius: 10px;
      overflow: hidden;
      height: 80px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      .group {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 20px;
        width: 65%;
        .delete-btn {
          width: 40px;
          height: 40px;
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ff2d2d;
          color: #ff2d2d;
          cursor: pointer;
          border-radius: 50%;
          text-decoration: none;
        }
        .title {
          font-size: 18px;
          text-decoration: none;
          color: #000;
          cursor: pointer;
          font-weight: bold;
          text-align: end;
        }
      }
      .logo {
        width: 35%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
