.admin-buildWebsite-page {
  padding: 0 10px;
  width: calc(100% - 20px);
  min-height: 100dvh;
  height: max-content;
  background-color: #efefef;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 120px;
  .back-icon {
    position: absolute;
    top: 13px;
    right: 13px;
    width: 40px;
    height: 40px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #000;
    color: #000;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
  }
  .add-logo {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 32px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .logo {
      width: 153px;
      height: 153px;
      border-radius: 20px;
      border: 1px solid #121212b1;
      background-color: #fff;
      margin-top: 10px;
      color: #121212;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      i {
        font-size: 90px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }
  .data {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    input {
      height: 45px;
      border-radius: 10px;
      outline: none;
      border: 1px solid #12121256;
      padding-right: 20px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    .customURL {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      width: 100%;
      .url {
        direction: ltr;
        color: #636363;
        width: 73%;
      }
      input {
        width: 33%;
        height: 30px;
        padding: 0;
        padding-left: 10px;
        direction: ltr;
      }
    }
  }
  .links {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    .link {
      width: 55px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border: 1px solid #12121256;
      font-size: 20px;
      border-radius: 7px;
      cursor: pointer;
      &.active {
        background-color: #303030;
        color: #fff;
      }
    }
  }
  .arrange-btn {
    width: 60%;
    height: 50px;
    background-color: #fff;
    border: 1px solid #1212128a;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .theme-group {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .head-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    .group {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .theme {
        width: 48%;
        height: 40px;
        background-color: #fff;
        border: 1px solid #1212128a;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        input[type="radio"] {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          z-index: 1000;
        }
        input[type="color"] {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background: none;
          border: 0;
          cursor: pointer;
          width: 120%;
          height: 140%;
          padding: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }
  .font-group {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
    .head-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    .group {
      position: relative;
      height: 40px;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #121212b1;
      input[type="color"] {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: none;
        border: 0;
        border-radius: 20px;
        cursor: pointer;
        width: 120%;
        height: 140%;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
  .btn-build-website {
    width: 100%;
    position: fixed;
    z-index: 10001;
    height: 80px;
    background: rgba(228, 228, 228, 0.27);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.8px);
    -webkit-backdrop-filter: blur(3.8px);
    background: rgba(224, 224, 224, 0.27);
    border-radius: 16px 16px 0 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.8px);
    -webkit-backdrop-filter: blur(3.8px);
    bottom: 0;
    left: 00;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 80%;
      height: 60px;
      background-color: #030713;
      color: #fff;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      cursor: pointer;
    }
    .loader {
      width: 15px;
      aspect-ratio: 1;
      border-radius: 50%;
      animation: l5 1s infinite linear alternate;
    }
    @keyframes l5 {
      0% {
        box-shadow: 20px 0 #000, -20px 0 #0002;
        background: #000;
      }
      33% {
        box-shadow: 20px 0 #000, -20px 0 #0002;
        background: #0002;
      }
      66% {
        box-shadow: 20px 0 #0002, -20px 0 #000;
        background: #0002;
      }
      100% {
        box-shadow: 20px 0 #0002, -20px 0 #000;
        background: #000;
      }
    }
  }
}
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #030713;
    --active-inner: #fff;
    --focus: 2px rgba(3, 5, 12, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type="checkbox"] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
  input[type="radio"] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, 0.7));
    }
    &:checked {
      --s: 0.5;
    }
  }
}
