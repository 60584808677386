.admin-home-page {
  padding: 0 10px;
  width: calc(100% - 20px);
    height: 100vh;
    /* max-height: 100dvh; */
    min-height: 100dvh;
  background-color: #efefef;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-size: 20px;
    position: relative;
    width: 100%;
    .logout {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      border: 1px solid #121212;
      padding: 10px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }
  }
  .logo {
    margin-top: -30px;
  }
  .actions {
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 274px;
    gap: 25px;

    .action {
      width: 100%;
      height: 48%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #fff;
      border-radius: 15px;
      border: 1px solid #121212;
      text-decoration: none;
      color: #121212;
      .action-icon {
        text-decoration: none;
        font-size: 120px;
      }
      .action-title {
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
